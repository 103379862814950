import { BANK_ID_SCOPES } from '@constants/bankId.js';
import { ENVELOPE_LANGUAGES } from '@constants/languages.js';

/** @depracted Use EnvelopeStatus from @constants/envelope instead */
export const ENVELOPE_STATUSES = {
  DRAFT: 'draft',
  SENT: 'sent',
  COMPLETED: 'completed',
  DECLINED: 'declined',
  EXPIRED: 'expired',
  CANCELLED: 'cancelled',
  CORRECTION: 'correction',
  SEALED: 'sealed',
  APPROVED: 'approved',
  DISAPPROVED: 'disapproved',
};

export const FILTER_KEY = {
  ACCOUNT_STATUS: 'status[IN]',
};

export const ENVELOPE_ACTIONS = {
  CLONE: 'clone',
  SET_LABELS: 'set_labels',
  DISCARD: 'discard',
  RESTORE: 'restore',
  DELETE: 'delete',
  START_CORRECTION: 'start_correction',
  UPDATE: 'update',
  RESEND: 'resend',
  CANCEL: 'cancel',
};

export const SORTED_ENVELOPE_ACTIONS = [
  ENVELOPE_ACTIONS.RESEND,
  ENVELOPE_ACTIONS.START_CORRECTION,
  ENVELOPE_ACTIONS.UPDATE,
  ENVELOPE_ACTIONS.CLONE,
  ENVELOPE_ACTIONS.CANCEL,
  ENVELOPE_ACTIONS.DISCARD,
  ENVELOPE_ACTIONS.DELETE,
  ENVELOPE_ACTIONS.SET_LABELS,
];

export const ENVELOPE_TEMPLATES_ACTIONS = {
  DELETE: 'delete',
  CLONE: 'clone',
  BATCH_SENDING: 'batch_sending',
};

export const RECIPIENT_PREFILL_TYPES = {
  NONE: 'none',
  FROM_SENDER: 'from_sender',
  FROM_TEMPLATE: 'from_template',
};

export const DELIVERY_STATUSES = {
  CANCELLED: 'cancelled',
  COMPLETED: 'completed',
  DOWNLOADED: 'downloaded',
  DRAFT: 'draft',
  SENT: 'sent',
};

export const RECIPIENT_STATUSES = {
  DRAFT: 'draft',
  WAITING: 'waiting',
  SENT: 'sent',
  DELIVERED: 'delivered',
  DOWNLOADED: 'downloaded',
  NON_DELIVERED: 'nonDelivered',
  SIGNED: 'signed',
  DECLINED: 'declined',
  CANCELLED: 'cancelled',
  AUTH_FAILED: 'authFailed',
  EXPIRED: 'expired',
  DISAPPROVED: 'disapproved',
  AUTH_WAITING: 'authWaiting',
};

export const RECIPIENT_STATUS_TO_STATUS_AT = {
  [RECIPIENT_STATUSES.SENT]: 'sentAt',
  [RECIPIENT_STATUSES.DELIVERED]: 'deliveredAt',
  [RECIPIENT_STATUSES.DOWNLOADED]: 'downloadedAt',
  [RECIPIENT_STATUSES.NON_DELIVERED]: 'nonDeliveredAt',
  [RECIPIENT_STATUSES.SIGNED]: 'signedAt',
  [RECIPIENT_STATUSES.DECLINED]: 'declinedAt',
  [RECIPIENT_STATUSES.CANCELLED]: 'cancelledAt',
  [RECIPIENT_STATUSES.AUTH_FAILED]: 'authFailedAt',
};

export const RECIPIENT_APPROVAL_MODE = {
  PER_ENVELOPE: 'per_envelope',
  PER_DOCUMENT: 'per_document',
  PER_DOCUMENT_HIDDEN: 'per_document_hidden',
};

export const RECIPIENT_ATTACHMENTS_VISIBILITY_TYPES = {
  APPROVE: 'approve',
  SHOW: 'show',
  HIDE: 'hide',
  SIGN: 'sign',
};

export const AVAILABLE_RECIPIENT_ATTACHMENTS_VISIBILITY_TYPES = [
  RECIPIENT_ATTACHMENTS_VISIBILITY_TYPES.APPROVE,
  RECIPIENT_ATTACHMENTS_VISIBILITY_TYPES.SIGN,
  RECIPIENT_ATTACHMENTS_VISIBILITY_TYPES.SHOW,
  RECIPIENT_ATTACHMENTS_VISIBILITY_TYPES.HIDE,
];

export const USER_ROLES = {
  ADMIN: 'admin',
  MANAGER: 'manager',
  USER: 'user',
  TEMPLATES: 'templates',
};

export const USER_STATUSES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DECLINED: 'declined',
  UNVERIFIED: 'unverified',
};

export const USER_ACTIONS = {
  DIS_INVITE: 'disinvite',
  UPDATE: 'update',
  DEACTIVATE: 'deactivate',
  ACTIVATE: 'activate',
};

export const ACCOUNT_STATUSES = {
  ACTIVATE: 'active',
  INACTIVE: 'inactive',
};

export const API_KEY_STATUSES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const API_KEY_ACTIONS = {
  UPDATE: 'update',
  DEACTIVATE: 'deactivate',
  ACTIVATE: 'activate',
};

export const BRANDING_ACTIONS = {
  UPDATE: 'update',
  DELETE: 'delete',
};

export const SIGNATURE_SCENARIOS_ACTIONS = {
  UPDATE: 'update',
  DELETE: 'delete',
};

export const IDENTIFY_SCENARIOS_ACTIONS = {
  UPDATE: 'update',
  DELETE: 'delete',
};

export const CONTACTS_ACTIONS = {
  UPDATE: 'update',
  DELETE: 'delete',
};

const ORDERED_SIGNATURE_FIELDS = {
  SIGNATURE: 'signature',
  APPROVAL: 'approval',
};

export const ORDERED_FORM_FIELDS = {
  TEXT: 'text',
  CHECKBOX: 'checkbox',
  RADIO_BUTTON: 'radio_button',
  ATTACHMENT: 'attachment',
  DOCUMENT: 'document',
  DATE_OF_SIGNATURE: 'date_of_signature',
};

export const SIGNATURE_FIELDS = {
  ...ORDERED_SIGNATURE_FIELDS,
  ...ORDERED_FORM_FIELDS,
};

export const ALLOWED_ENVELOPE_ACTIONS = {
  ENVELOPE_RESEND: 'envelopeResend',
  ENVELOPE_CANCEL: 'envelopeCancel',
  RECIPIENT_RESEND: 'recipientResend',
  RECIPIENT_EDIT: 'recipientEdit',
  RECIPIENT_DELETE: 'recipientDelete',
  SIGNATURE_TAG_MANIPULATION: 'signatureTagManipulation',
  APPROVAL_TAG_MANIPULATION: 'approvalTagManipulation',
  ATTACHMENT_TAG_MANIPULATION: 'attachmentTagManipulation',
  DOCUMENT_TAG_MANIPULATION: 'documentTagManipulation',
  DATE_OF_SIGNATURE_TAG_MANIPULATION: 'dateOfSignatureTagManipulation',
  ENVELOPE_SEND: 'envelopeSend',
  DOCUMENT_REPLACE: 'documentReplace',
  DOCUMENT_RENAME: 'documentRename',
  RADIO_BUTTON_TAG_MANIPULATION: 'radioButtonTagManipulation',
  TEXT_TAG_MANIPULATION: 'textTagManipulation',
  CHECKBOX_TAG_MANIPULATION: 'checkboxTagManipulation',
};
export const SIGNATURE_FIELDS_TO_ALLOWED_ACTIONS = {
  [SIGNATURE_FIELDS.SIGNATURE]: ALLOWED_ENVELOPE_ACTIONS.SIGNATURE_TAG_MANIPULATION,
  [SIGNATURE_FIELDS.APPROVAL]: ALLOWED_ENVELOPE_ACTIONS.APPROVAL_TAG_MANIPULATION,
  [SIGNATURE_FIELDS.TEXT]: ALLOWED_ENVELOPE_ACTIONS.TEXT_TAG_MANIPULATION,
  [SIGNATURE_FIELDS.CHECKBOX]: ALLOWED_ENVELOPE_ACTIONS.CHECKBOX_TAG_MANIPULATION,
  [SIGNATURE_FIELDS.RADIO_BUTTON]: ALLOWED_ENVELOPE_ACTIONS.RADIO_BUTTON_TAG_MANIPULATION,
  [SIGNATURE_FIELDS.ATTACHMENT]: ALLOWED_ENVELOPE_ACTIONS.ATTACHMENT_TAG_MANIPULATION,
  [SIGNATURE_FIELDS.DOCUMENT]: ALLOWED_ENVELOPE_ACTIONS.DOCUMENT_TAG_MANIPULATION,
  [SIGNATURE_FIELDS.DATE_OF_SIGNATURE]: ALLOWED_ENVELOPE_ACTIONS.DATE_OF_SIGNATURE_TAG_MANIPULATION,
};

export const CERTIFICATE_STATUSES = {
  VALID: 'valid',
  INVALID: 'invalid',
  ERROR: 'error',
  DISABLED: 'disabled',
};

export const SIGNATURE_ATTRIBUTE_FILTER_LIST = {
  type: false,
  document: false,
  recipient: false,
  xPosition: false,
  yPosition: false,
  positioning: false,
  page: false,
  required: [
    SIGNATURE_FIELDS.SIGNATURE,
    SIGNATURE_FIELDS.DOCUMENT,
    SIGNATURE_FIELDS.TEXT,
    SIGNATURE_FIELDS.CHECKBOX,
    SIGNATURE_FIELDS.ATTACHMENT,
    SIGNATURE_FIELDS.RADIO_BUTTON,
  ],
  label: [SIGNATURE_FIELDS.TEXT, SIGNATURE_FIELDS.CHECKBOX, SIGNATURE_FIELDS.RADIO_BUTTON],
  name: [SIGNATURE_FIELDS.DOCUMENT, SIGNATURE_FIELDS.ATTACHMENT],
  customIdentifier: [SIGNATURE_FIELDS.TEXT],
  layout: [SIGNATURE_FIELDS.DOCUMENT],
  group: [SIGNATURE_FIELDS.RADIO_BUTTON],
  readonly: [SIGNATURE_FIELDS.TEXT, SIGNATURE_FIELDS.CHECKBOX, SIGNATURE_FIELDS.RADIO_BUTTON],
  value: [SIGNATURE_FIELDS.TEXT, SIGNATURE_FIELDS.CHECKBOX, SIGNATURE_FIELDS.RADIO_BUTTON],
  width: [SIGNATURE_FIELDS.TEXT],
  height: [SIGNATURE_FIELDS.TEXT],
  scale: [SIGNATURE_FIELDS.SIGNATURE],
  bankIdClaim: [SIGNATURE_FIELDS.TEXT],
  recipientClaim: [SIGNATURE_FIELDS.TEXT],
  assignment: [SIGNATURE_FIELDS.TEXT, SIGNATURE_FIELDS.CHECKBOX, SIGNATURE_FIELDS.RADIO_BUTTON],
  format: [SIGNATURE_FIELDS.DATE_OF_SIGNATURE],
};

export const DOCUMENT_TAG_LAYOUT = {
  FRONT_AND_BACK: 'front_and_back',
  ONE_SIDE: 'one_side',
};

export const FIELD_SIZE = {
  APPROVAL: {
    width: 108.631,
    height: 65.715,
  },
  BASE: {
    width: 207.874,
    height: 79.37,
  },
  LINE: {
    width: 266.666,
    height: 18,
  },
  CHECKBOX: {
    width: 19.874,
    height: 19.874,
  },
  DATE: {
    width: 99,
    height: 18,
  },
};

export const ASSIGNMENT_ROLE = {
  SENDER: 'sender',
  RECIPIENT: 'recipient',
};

export const NOTIFICATION_TYPES = {
  TO_SIGN_AFTER_SENT: 'toSignAfterSent',
  TO_SIGN_BEFORE_EXPIRES: 'toSignBeforeExpires',
};

export const SIGNATURE_TYPES = {
  SIMPLE: 'simple',
  BIOMETRIC: 'biometric',
  BANK_ID_SIGN: 'bank_id_sign',
  CERTIFICATE_AKV: 'certificate_akv',
  CERTIFICATE_FILE: 'certificate_file',
  BY_SCENARIO: 'by_scenario',
};

export const SIGNATURE_SUBJECTS = {
  ENVELOPE: 'envelope',
  DOCUMENT: 'document',
};

export const REDIRECT_ERROR_SOURCE = {
  BANK_ID: 'bank_id',
  CERTIFICATE: 'certificate',
};

export const AUTHENTICATION_METHOD = {
  NONE: 'none',
  SMS: 'sms',
  BANK_ID: 'bankId',
  SMS_OR_BANK_ID: 'sms_or_bankId',
  IDENTIFY: 'identify',
  BY_SCENARIO: 'by_scenario',
};

export const AUTHENTICATION_PLACE = {
  OPEN: 'onOpen',
  SIGNATURE: 'onSignature',
  BOTH: 'onBoth',
  DOWNLOAD: 'onDownload',
};

// similar to previous AUTHENTICATION_PLACE but with updated value, old is still there because backward compatibility
export const AUTHENTICATION_PLACE_PROPERTY = {
  OPEN: 'authenticationOnOpen',
  SIGNATURE: 'authenticationOnSignature',
  DOWNLOAD: 'authenticationOnDownload',
};

export const INVITATION_PLACE = {
  SIGNATURE: 'channelForSigner',
  DOWNLOAD: 'channelForDownload',
};

export const REQUEST_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATH: 'PATCH',
  DELETE: 'DELETE',
};

export const WEBHOOK_EVENT = {
  ENVELOPE_SENT: 'envelopeSent',
  ENVELOPE_COMPLETED: 'envelopeCompleted',
  ENVELOPE_EXPIRED: 'envelopeExpired',
  ENVELOPE_DECLINED: 'envelopeDeclined',
  ENVELOPE_CANCELLED: 'envelopeCancelled',
  ENVELOPE_DELETED: 'envelopeDeleted',
  ENVELOPE_DISAPPORVED: 'envelopeDisapproved',
  RECIPIENT_SENT: 'recipientSent',
  RECIPIENT_DELIVERED: 'recipientDelivered',
  RECIPIENT_NON_DELIVERED: 'recipientNonDelivered',
  RECIPIENT_AUTH_FAILED: 'recipientAuthFailed',
  RECIPIENT_SIGNED: 'recipientSigned',
  RECIPIENT_DOWNLOADED: 'recipientDownloaded',
  RECIPIENT_DECLINED: 'recipientDeclined',
  RECIPIENT_DISAPPROVED: 'recipientDisapproved',
  RECIPIENT_CANCELLED: 'recipientCancelled',
  DELIVERY_SENT: 'deliverySent',
  DELIVERY_DOWNLOADED: 'deliveryDownloaded',
  DELIVERY_CANCELLED: 'deliveryCancelled',
  DELIVERY_RECIPIENT_SENT: 'deliveryRecipientSent',
  DELIVERY_RECIPIENT_DOWNLOADED: 'deliveryRecipientDownloaded',
  DELIVERY_RECIPIENT_NON_DELIVERED: 'deliveryRecipientNonDelivered',
  DELIVERY_RECIPIENT_CANCELLED: 'deliveryRecipientCancelled',
  IDENTIFICATION_COMPLETED: 'identificationCompleted',
  IDENTIFICATION_APPROVED: 'identificationApproved',
  IDENTIFICATION_DENIED: 'identificationDenied',
  IDENTIFICATION_FOR_REVIEW: 'identificationForReview',
};

export const WEBHOOK_STATUSES = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
};

export const WEBHOOK_ACTIONS = {
  DELETE: 'delete',
  RESUME: 'resume',
  PAUSE: 'pause',
};

export const FILE_CATEGORIES = {
  DOCUMENT: 'document',
  TABLE: 'table',
  IMAGE: 'image',
  PRESENTATION: 'presentation',
};

export const MIME_TYPES_ACCORDING_TO_FILE_CATEGORY = [
  {
    fileCategory: FILE_CATEGORIES.DOCUMENT,
    mimeTypes: [
      'application/acrobat',
      'application/msword',
      'application/msword-template',
      'application/nappdf',
      'application/oxps',
      'application/pdf',
      'application/rtf',
      'application/vnd.ms-outlook',
      'application/vnd.ms-word',
      'application/vnd.ms-word.document.macroenabled.12',
      'application/vnd.ms-word.template.macroenabled.12',
      'application/vnd.ms-xpsdocument',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
      'application/vnd.wordperfect',
      'application/wordperfect',
      'application/x-msword',
      'application/x-pdf',
      'application/x-wordperfect',
      'application/xps',
      'image/pdf',
      'text/html',
      'text/plain',
      'text/rtf',
      'text/x-gettext-translation-template',
    ],
  },
  {
    fileCategory: FILE_CATEGORIES.TABLE,
    mimeTypes: [
      'application/msexcel',
      'application/vnd.ms-excel',
      'application/vnd.ms-excel.sheet.macroenabled.12',
      'application/vnd.oasis.opendocument.spreadsheet',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/x-msexcel',
      'text/csv',
      'text/x-comma-separated-values',
      'text/x-csv',
    ],
  },
  {
    fileCategory: FILE_CATEGORIES.IMAGE,
    mimeTypes: [
      'image/bmp',
      'image/gif',
      'image/jpeg',
      'image/pjpeg',
      'image/png',
      'image/tiff',
      'image/x-bmp',
      'image/x-ms-bmp',
    ],
  },
  {
    fileCategory: FILE_CATEGORIES.PRESENTATION,
    mimeTypes: [
      'application/mspowerpoint',
      'application/powerpoint',
      'application/vnd.ms-powerpoint',
      'application/vnd.ms-powerpoint.presentation.macroenabled.12',
      'application/vnd.oasis.opendocument.presentation',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.presentationml.template',
      'application/x-mspowerpoint',
      'text/x-pot',
    ],
  },
];

export function pruneTagUnwantedAttributesByTagType(data, tagType, disabledAttributes = []) {
  return Object.entries(SIGNATURE_ATTRIBUTE_FILTER_LIST).reduce((filteredAttributes, [attribute, allowedTags]) => {
    if (!disabledAttributes.includes(attribute)) {
      if (!allowedTags) {
        filteredAttributes[attribute] = data[attribute];
      } else if (allowedTags.includes(tagType)) {
        filteredAttributes[attribute] = data[attribute];
      }
    }
    return filteredAttributes;
  }, {});
}

export function getFileCategoryByMimeType(mimeType) {
  return MIME_TYPES_ACCORDING_TO_FILE_CATEGORY.find(fileCategoryWithMimeTypes => {
    return !!fileCategoryWithMimeTypes.mimeTypes.find(categoryMimeType => categoryMimeType === mimeType);
  }).fileCategory;
}

export function getFileNameAndExtension(fullFileName = '') {
  const re = /(?:\.([^.]+))?$/;
  const fileExt = re.exec(fullFileName)[1];
  const fileName = fullFileName.replace(`.${fileExt}`, '');
  return {
    name: fileName,
    extension: fileExt,
  };
}

export function getWebhookStatusClasses(status) {
  const variants = {
    [WEBHOOK_STATUSES.ENABLED]: 'bg-green-500',
    [WEBHOOK_STATUSES.DISABLED]: 'bg-gray-300',
  };
  return variants[status] || '';
}
export function getAuthenticationPlaces() {
  const placePriority = place => {
    switch (place) {
      case AUTHENTICATION_PLACE.OPEN:
        return 0;
      case AUTHENTICATION_PLACE.SIGNATURE:
        return 1;
      case AUTHENTICATION_PLACE.DOWNLOAD:
        return 2;
      default: // others
        return 9;
    }
  };
  return Object.values(AUTHENTICATION_PLACE)
    .sort((a, b) => placePriority(a) - placePriority(b))
    .map(place => `authentication${capitalize(place)}`);
}

export function getIteratorItemsCount(iterator) {
  let count = 0;
  while (!iterator.next().done) count++;
  return count;
}

export function getAuthenticationMethodOptions() {
  return Object.values(AUTHENTICATION_METHOD).map(value => ({
    label: `recipient.authenticationMethod.${value}`,
    value,
  }));
}

export function getFirstAuthMethodFromRecipient(recipient) {
  const authPlaces = getAuthenticationPlaces();
  const nonNoneAuthPlaces = authPlaces.filter(method => {
    return (
      (recipient?.[method]?.value ?? recipient?.[method]) !== AUTHENTICATION_METHOD.NONE &&
      method !== `authentication${capitalize(AUTHENTICATION_PLACE.BOTH)}`
    );
  });
  const firstAuthMethod = nonNoneAuthPlaces.shift() ?? getAuthenticationPlaces()[0];
  return recipient?.[firstAuthMethod];
}

//BANK_ID_SCOPES
//ADDITIONAL_FIELDS

export const ADDITIONAL_FIELDS = {
  NAME: 'name',
  MOBILE: 'mobile',
  EMAIL: 'email',
  COMPANY: 'company',
  IDENTIFICATION_NUMBER: 'identificationNumber',
  FUNCTION: 'function',
  CONTRACTING_PARTY: 'contractingParty',
  BIRTH_DATE: 'birthdate',
  BIRTH_NUMBER: 'birthnumber',
  ADDRESS: 'address',
};

export function mappingBankIdScopesValidatedFields(key) {
  switch (key) {
    // BankIdScope -> AdditionalField
    case BANK_ID_SCOPES.PROFILE_NAME:
      return ADDITIONAL_FIELDS.NAME;
    case BANK_ID_SCOPES.PROFILE_PHONENUMBER:
      return ADDITIONAL_FIELDS.MOBILE;
    case BANK_ID_SCOPES.PROFILE_EMAIL:
      return ADDITIONAL_FIELDS.EMAIL;
    case BANK_ID_SCOPES.PROFILE_BIRTHDATE:
      return ADDITIONAL_FIELDS.BIRTH_DATE;
    case BANK_ID_SCOPES.PROFILE_BIRTHNUMBER:
      return ADDITIONAL_FIELDS.BIRTH_NUMBER;

    // AdditionalField -> BankIdScope
    case ADDITIONAL_FIELDS.NAME:
      return BANK_ID_SCOPES.PROFILE_NAME;
    case ADDITIONAL_FIELDS.MOBILE:
      return BANK_ID_SCOPES.PROFILE_PHONENUMBER;
    case ADDITIONAL_FIELDS.EMAIL:
      return BANK_ID_SCOPES.PROFILE_EMAIL;
    case ADDITIONAL_FIELDS.BIRTH_DATE:
      return BANK_ID_SCOPES.PROFILE_BIRTHDATE;
    case ADDITIONAL_FIELDS.BIRTH_NUMBER:
      return BANK_ID_SCOPES.PROFILE_BIRTHNUMBER;

    default:
      return console.error('[DGS] Unknown mapping key in mappingBankIdScopesValidatedFields');
  }
}

export const CHANNEL = {
  EMAIL: 'email',
  EMAIL_AND_SMS: 'email_and_sms',
  NONE: 'none',
};

export const TAGS_POSITIONS = {
  TOP_LEFT: 'top_left',
  BOTTOM_LEFT: 'bottom_left',
  MIDDLE_LEFT: 'middle_left',
  TOP_RIGHT: 'top_right',
  BOTTOM_RIGHT: 'bottom_right',
  MIDDLE_RIGHT: 'middle_right',
  TOP_CENTER: 'top_center',
  BOTTOM_CENTER: 'bottom_center',
  CENTER: 'center',
};

export const LABEL_POSITIONING = {
  NONE: 'none',
  TOP_LEFT: TAGS_POSITIONS.TOP_LEFT,
  TOP_CENTER: TAGS_POSITIONS.TOP_CENTER,
  TOP_RIGHT: TAGS_POSITIONS.TOP_RIGHT,
  BOTTOM_LEFT: TAGS_POSITIONS.BOTTOM_LEFT,
  BOTTOM_CENTER: TAGS_POSITIONS.BOTTOM_CENTER,
  BOTTOM_RIGHT: TAGS_POSITIONS.BOTTOM_RIGHT,
};

export const ENVIRONMENT_NAMESPACES = {
  SELFCARE: 'selfcare',
  EMBEDDED: 'embedded',
};

export const TAG_EDITOR_REALMS = {
  ENVELOPES: 'envelopes',
  ENVELOPE_TEMPLATES: 'envelope_templates',
};

export const LOCAL_STORAGE_ITEMS = {
  UNVERIFIED_ACCOUNTS_FROM_PREVIOUS_SESSION: 'dgs_unverifiedAccountsFromPreviousSession',
  HELP_TOOLTIP: 'dgs_helpTooltip',
  I18N_CACHE: 'dgs_i18nCache',
  FILTER_STATE: 'dgs_filterState',
  DOWNLOAD_DIALOG_STATE: 'dgs_downloadDialogState',
  REMEMBER_TOS: 'dgs_rememberTos',
};
export const HELP_TOOLTIPS = {
  PREFILL_RECIPIENT: 'prefillRecipient',
  MERGE_DOCUMENTS: 'mergeDocuments',
  SIGNATURE_FIELDS: 'signatureFields',
  FORM_FIELDS: 'formFields',
};
export const COMPONENT_STYLE_VARIANT = {
  GRAY: 'gray',
  WHITE: 'white',
  GRAY_WHITE: 'grayWhite',
};
export const PERMISSIONS = {
  // ROLES
  ROLE_API_KEY: 'ROLE_API_KEY',
  ROLE_ACCOUNT_USER: 'ROLE_ACCOUNT_USER',
  ROLE_USER: 'ROLE_USER',
  ROLE_TEMPLATES: 'ROLE_TEMPLATES',
  ROLE_MANAGER: 'ROLE_MANAGER',
  ROLE_ADMIN: 'ROLE_ADMIN',
  // PERMISSIONS
  ROLE_API: 'ROLE_API',
  ROLE_ACCOUNT_READ: 'ROLE_ACCOUNT_READ',
  ROLE_ACCOUNT_WRITE: 'ROLE_ACCOUNT_WRITE',
  ROLE_ACCOUNT_SECURITY_WRITE: 'ROLE_ACCOUNT_SECURITY_WRITE',
  ROLE_APIKEY_READ: 'ROLE_APIKEY_READ',
  ROLE_APIKEY_WRITE: 'ROLE_APIKEY_WRITE',
  ROLE_USER_READ: 'ROLE_USER_READ',
  ROLE_USER_WRITE: 'ROLE_USER_WRITE',
  ROLE_USER_WRITE_ADMIN: 'ROLE_USER_WRITE_ADMIN',
  ROLE_DELIVERY_CREATE: 'ROLE_DELIVERY_CREATE',
  ROLE_DELIVERY_READ: 'ROLE_DELIVERY_READ',
  ROLE_DELIVERY_WRITE: 'ROLE_DELIVERY_WRITE',
  ROLE_ENVELOPE_CREATE: 'ROLE_ENVELOPE_CREATE',
  ROLE_ENVELOPE_READ: 'ROLE_ENVELOPE_READ',
  ROLE_ENVELOPE_WRITE: 'ROLE_ENVELOPE_WRITE',
  ROLE_ENVELOPE_WRITE_FROM_TEMPLATE: 'ROLE_ENVELOPE_WRITE_FROM_TEMPLATE',
  ROLE_ENVELOPE_TEMPLATE_READ: 'ROLE_ENVELOPE_TEMPLATE_READ',
  ROLE_ENVELOPE_TEMPLATE_WRITE: 'ROLE_ENVELOPE_TEMPLATE_WRITE',
  ROLE_ENVELOPE_TEMPLATE_SHARE: 'ROLE_ENVELOPE_TEMPLATE_SHARE',
  ROLE_ENVELOPE_TEMPLATE_CREATE: 'ROLE_ENVELOPE_TEMPLATE_CREATE',
  ROLE_LABEL_WRITE: 'ROLE_LABEL_WRITE',
  ROLE_FILE_READ: 'ROLE_FILE_READ',
  ROLE_FILE_WRITE: 'ROLE_FILE_WRITE',
  ROLE_IMAGE_READ: 'ROLE_IMAGE_READ',
  ROLE_IMAGE_WRITE: 'ROLE_IMAGE_WRITE',
  ROLE_WEBHOOK_READ: 'ROLE_WEBHOOK_READ',
  ROLE_WEBHOOK_WRITE: 'ROLE_WEBHOOK_WRITE',
  ROLE_CERTIFICATE_READ: 'ROLE_CERTIFICATE_READ',
  ROLE_CERTIFICATE_WRITE: 'ROLE_CERTIFICATE_WRITE',
  ROLE_BRANDING_READ: 'ROLE_BRANDING_READ',
  ROLE_BRANDING_WRITE: 'ROLE_BRANDING_WRITE',
  ROLE_REPORT_READ: 'ROLE_REPORT_READ',
  ROLE_REQUEST_READ: 'ROLE_REQUEST_READ',
  ROLE_SIGNATURE_SCENARIO_WRITE: 'ROLE_SIGNATURE_SCENARIO_WRITE',
  ROLE_SIGNATURE_SCENARIO_READ: 'ROLE_SIGNATURE_SCENARIO_READ',
  ROLE_IDENTIFY_SCENARIO_WRITE: 'ROLE_IDENTIFY_SCENARIO_WRITE',
  ROLE_IDENTIFY_SCENARIO_READ: 'ROLE_IDENTIFY_SCENARIO_READ',
  ROLE_BATCH_SENDING_READ_ALL: 'ROLE_BATCH_SENDING_READ_ALL',
  ROLE_BATCH_SENDING_READ: 'ROLE_BATCH_SENDING_READ',
  ROLE_BATCH_SENDING_WRITE: 'ROLE_BATCH_SENDING_WRITE',
  ROLE_BATCH_SENDING_WRITE_ALL: 'ROLE_BATCH_SENDING_WRITE_ALL',
  ROLE_BILLING_WRITE: 'ROLE_BILLING_WRITE',
};

export const ACTIONS = {
  ACTION_CREATE_BRANDING: 'create_branding',
  ACTION_INVITE_USER: 'invite_user',
};

export const LABEL_TAG_VARIANT = {
  NORMAL: 'normal',
  REMOVE: 'remove',
  ADD: 'add',
};

export const LABEL_COLORS = {
  GRAY: 'gray',
  GREEN: 'green',
  BLUE: 'blue',
  PURPLE: 'purple',
  PINK: 'pink',
  RED: 'red',
  ORANGE: 'orange',
  YELLOW: 'yellow',
};

export const LABEL_COLORS_STYLE = {
  [LABEL_COLORS.GRAY]: {
    normal: '#7B7B7B',
    faded: '#E3E3E3',
  },
  [LABEL_COLORS.GREEN]: {
    normal: '#008F7A',
    faded: '#B8E6DF',
  },
  [LABEL_COLORS.BLUE]: {
    normal: '#0081CF',
    faded: '#C4E2F5',
  },
  [LABEL_COLORS.PURPLE]: {
    normal: '#845EC2',
    faded: '#D8C5F6',
  },
  [LABEL_COLORS.PINK]: {
    normal: '#D65DB1',
    faded: '#F5C4E6',
  },
  [LABEL_COLORS.RED]: {
    normal: '#FF9671',
    faded: '#FFD9CC',
  },
  [LABEL_COLORS.ORANGE]: {
    normal: '#FFC75F',
    faded: '#FFEAC2',
  },
  [LABEL_COLORS.YELLOW]: {
    normal: '#EFEE64',
    faded: '#F7F7BC',
  },
};

export const DISCARDED_SIGN = 'discarded';

export function haveAccessToPermission(permissionsList, permission) {
  return (permissionsList ?? []).includes(permission);
}

export function tagPositionTransformResolver(position) {
  switch (position) {
    case TAGS_POSITIONS.TOP_LEFT:
    default:
      return [];
    case TAGS_POSITIONS.TOP_CENTER:
      return ['-translate-x-1/2'];
    case TAGS_POSITIONS.TOP_RIGHT:
      return ['-translate-x-full'];
    case TAGS_POSITIONS.MIDDLE_LEFT:
      return ['-translate-y-1/2'];
    case TAGS_POSITIONS.CENTER:
      return ['-translate-y-1/2', '-translate-x-1/2'];
    case TAGS_POSITIONS.MIDDLE_RIGHT:
      return ['-translate-y-1/2', '-translate-x-full'];
    case TAGS_POSITIONS.BOTTOM_LEFT:
      return ['-translate-y-full'];
    case TAGS_POSITIONS.BOTTOM_CENTER:
      return ['-translate-y-full', '-translate-x-1/2'];
    case TAGS_POSITIONS.BOTTOM_RIGHT:
      return ['-translate-y-full', '-translate-x-full'];
  }
}

export function convertTagPositioningToTransformOrigin(positioning) {
  switch (positioning) {
    case TAGS_POSITIONS.CENTER:
      return 'origin-center';
    case TAGS_POSITIONS.TOP_CENTER:
      return 'origin-top';
    case TAGS_POSITIONS.TOP_RIGHT:
      return 'origin-top-right';
    case TAGS_POSITIONS.MIDDLE_RIGHT:
      return 'origin-right';
    case TAGS_POSITIONS.BOTTOM_RIGHT:
      return 'origin-bottom-right';
    case TAGS_POSITIONS.BOTTOM_CENTER:
      return 'origin-bottom';
    case TAGS_POSITIONS.BOTTOM_LEFT:
      return 'origin-bottom-left';
    case TAGS_POSITIONS.MIDDLE_LEFT:
      return 'origin-left';
    case TAGS_POSITIONS.TOP_LEFT:
      return 'origin-top-left';
    default:
      return 'origin-top-left';
  }
}

export const TAGS_DEFAULT_POSITION = TAGS_POSITIONS.TOP_LEFT;

export function getEnvelopeLanguagesOptions() {
  return ENVELOPE_LANGUAGES.map(value => ({ label: `languages.${value}`, value }));
}

export function getEnvelopeLanguagesOptionsShort() {
  return ENVELOPE_LANGUAGES.map(value => ({ label: `${value[0].toUpperCase()}${value.slice(1)}`, value }));
}

export function getEnvelopeStatusClasses(status) {
  const variants = {
    [ENVELOPE_STATUSES.CANCELLED]: 'bg-gray-300',
    [ENVELOPE_STATUSES.DECLINED]: 'bg-gray-300',
    [ENVELOPE_STATUSES.DRAFT]: 'bg-purple-400',
    [ENVELOPE_STATUSES.SENT]: 'bg-cyan-400',
    [ENVELOPE_STATUSES.SEALED]: 'bg-cyan-400',
    [ENVELOPE_STATUSES.COMPLETED]: 'bg-green-500',
    [RECIPIENT_STATUSES.EXPIRED]: 'bg-gray-300',
    [ENVELOPE_STATUSES.CORRECTION]: 'bg-yellow-500',
    [ENVELOPE_STATUSES.APPROVED]: 'bg-green-200',
    [ENVELOPE_STATUSES.DISAPPROVED]: 'bg-gray-300',
  };
  return variants[status] || '';
}

export function getDeliveryStatusClasses(status) {
  const variants = {
    [DELIVERY_STATUSES.CANCELLED]: 'bg-gray-300',
    [DELIVERY_STATUSES.DECLINED]: 'bg-gray-300',
    [DELIVERY_STATUSES.DRAFT]: 'bg-purple-400',
    [DELIVERY_STATUSES.SENT]: 'bg-cyan-400',
    [DELIVERY_STATUSES.DELIVERED]: 'bg-cyan-400',
    [DELIVERY_STATUSES.COMPLETED]: 'bg-green-500',
    [DELIVERY_STATUSES.DOWNLOADED]: 'bg-green-500',
    [RECIPIENT_STATUSES.EXPIRED]: 'bg-gray-300',
  };
  return variants[status] || '';
}

export function getUserStatusClasses(status) {
  const variants = {
    [USER_STATUSES.ACTIVE]: 'bg-green-500',
    [USER_STATUSES.INACTIVE]: 'bg-gray-300',
    [USER_STATUSES.UNVERIFIED]: 'bg-purple-400',
    [USER_STATUSES.DECLINED]: 'bg-gray-300',
  };
  return variants[status] || '';
}

export function getApiKeyStatusClasses(status) {
  const variants = {
    [API_KEY_STATUSES.ACTIVE]: 'bg-green-500',
    [API_KEY_STATUSES.INACTIVE]: 'bg-gray-300',
  };
  return variants[status] || '';
}

export function getRecipientStatusClasses(status) {
  const variants = {
    [RECIPIENT_STATUSES.DRAFT]: 'bg-purple-400',
    [RECIPIENT_STATUSES.WAITING]: 'bg-cyan-400',
    [RECIPIENT_STATUSES.SENT]: 'bg-cyan-400',
    [RECIPIENT_STATUSES.DELIVERED]: 'bg-cyan-400',
    [RECIPIENT_STATUSES.NON_DELIVERED]: 'bg-red-600',
    [RECIPIENT_STATUSES.SIGNED]: 'bg-green-500',
    [RECIPIENT_STATUSES.DECLINED]: 'bg-gray-300',
    [RECIPIENT_STATUSES.CANCELLED]: 'bg-gray-300',
    [RECIPIENT_STATUSES.DOWNLOADED]: 'bg-green-500',
    [RECIPIENT_STATUSES.AUTH_FAILED]: 'bg-red-600',
    [RECIPIENT_STATUSES.AUTH_WAITING]: 'bg-yellow-500',
    [RECIPIENT_STATUSES.EXPIRED]: 'bg-gray-300',
    [RECIPIENT_STATUSES.DISAPPROVED]: 'bg-gray-300',
  };
  return variants[status] || '';
}

export function getCertificateStatusClasses(status) {
  const variants = {
    [CERTIFICATE_STATUSES.VALID]: 'bg-green-500',
    [CERTIFICATE_STATUSES.INVALID]: 'bg-gray-300',
    [CERTIFICATE_STATUSES.ERROR]: 'bg-red-600',
    [CERTIFICATE_STATUSES.DISABLED]: 'bg-gray-300',
  };
  return variants[status] || '';
}

export function capitalize(str, everyWord = false) {
  return (everyWord ? String(str).split(' ') : [String(str)])
    .map(word => `${word[0].toUpperCase()}${word.substr(1)}`)
    .join(' ');
}

export function downloadFileUrl(fileData, mimetype) {
  const blob = new Blob([fileData], { type: mimetype });
  return window.URL.createObjectURL(blob);
}

export function dataURLToBlob(dataURL) {
  const parts = dataURL.split(';base64,');
  const contentType = parts[0].split(':')[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  return new Blob([uInt8Array], { type: contentType });
}

export function invokeBrowserDownloadFile(fileData, fileName, mimetype) {
  const link = document.createElement('a');
  const downloadUrl = downloadFileUrl(fileData, mimetype);
  // browser doesn't support download yet
  if (typeof link.download === 'undefined') {
    window.location = downloadUrl;
  } else {
    link.href = downloadUrl;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(link.href);
  }
  link.remove();
}

export function invokeLinkClick(url) {
  const link = document.createElement('a');
  link.href = url;
  link.click();
  link.remove();
}

export function generateUid() {
  // https://gist.github.com/jed/982883
  const b = a =>
    a
      ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
      : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, b);
  return `uid-${b()}`;
}

export function deepClone(obj) {
  if (Array.isArray(obj)) {
    return obj.map(deepClone);
  }
  if (obj && typeof obj === 'object') {
    const cloned = {};
    const keys = Object.keys(obj);
    for (let i = 0, l = keys.length; i < l; i += 1) {
      const key = keys[i];
      cloned[key] = deepClone(obj[key]);
    }
    return cloned;
  }
  return obj;
}

export function calculateStrength(password) {
  let passwordStrength = 0;
  if (password) {
    if (password.match(/^(.)\1+$/)) {
      return 1;
    }
    if (password.match(/[a-z]/)) {
      ++passwordStrength;
    }
    if (password.match(/[A-Z]/)) {
      ++passwordStrength;
    }
    if (password.match(/\d+/)) {
      ++passwordStrength;
    }

    if (password.match(/[^a-zA-Z0-9]/)) {
      ++passwordStrength;
    }
    if (password.length > 12) {
      ++passwordStrength;
    }
  }
  return passwordStrength;
}

export function propertyDrillDown(obj, path) {
  if (obj && typeof obj === 'object' && Object.keys(obj) && path) {
    const pathArray = path.split('.');
    let part = pathArray.shift();
    if (part.startsWith('[') && part.endsWith(']')) {
      part = parseInt(part.slice(1, -1), 10);
    }
    return propertyDrillDown(obj[part], pathArray.join('.'));
  }
  if (obj && !path) {
    return obj;
  }
  return undefined;
}

export function getLastItemFromPropertyPath(propertyPath, splitCharacter) {
  const splitIndex = propertyPath.lastIndexOf(splitCharacter) + 1;
  if (splitIndex < 0) {
    return propertyPath;
  }
  return propertyPath.substring(splitIndex);
}

export function storeAndGetHistoryTargetRoute(
  { dispatch, getters },
  { targetPage = undefined, returnPage, returnPageParams = undefined },
) {
  dispatch('historyRedirect/setTargetPage', targetPage);
  dispatch('historyRedirect/setReturnPage', returnPage);
  dispatch('historyRedirect/setReturnPageParams', returnPageParams);
  return getters['historyRedirect/getTargetPage'];
}

export function convertPtToPx(value) {
  if (!Number.isNaN(Number(value))) {
    // Constant 1.33 - pt to px conversion
    return Math.round((Number(value) * 4) / 3);
  }
  return null;
}

export function convertPxToPt(value) {
  if (!Number.isNaN(Number(value))) {
    return Math.round((Number(value) * 3) / 4);
  }
  return null;
}

/**
 * Modifies the URL by adding, updating, or removing a query parameter based on the given index.
 *
 * @param {string} urlParam - The name of the query parameter to modify.
 * @param {number} index - The index determining how the URL parameter should be updated.
 *                         If greater than 1, the parameter is set or updated with the provided value.
 *                         If 1 or less, the parameter is removed from the URL.
 * @param {string} url - The URL to modify. Defaults to the current window location.
 * @return {string} - The updated URL as a string.
 */
export function modifyParamUrl(urlParam, index, url = window.location.href) {
  const baseUrl = new URL(url);
  if (index > 1) {
    baseUrl.searchParams.set(urlParam, index);
  } else if (baseUrl.searchParams.has(urlParam)) {
    baseUrl.searchParams.delete(urlParam);
  }
  return baseUrl.toString();
}

export function createImage(dataURL) {
  const img = document.createElement('img');
  img.src = dataURL;
  return new Promise(resolve => {
    img.onload = () => {
      resolve(img);
    };
  });
}

export function getDragImage() {
  return createImage(
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAQAAAC0NkA6AAAALElEQVR42u3NMQEAAAwCoNm/9DL4eEEBcgORSCQSiUQikUgkEolEIpFIJJ0HCt8AM0IRFnUAAAAASUVORK5CYII=',
  );
}

const STATUSES_CODE_NAMES = {
  200: 'OK',
  201: 'Created',
  204: 'No Content',
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  415: 'Unsupported Media Type',
  422: 'Unprocessable Entity',
  429: 'Too Many Requests',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
};

const STATUS_COLOR = {
  GRAY: 'bg-gray-300',
  YELLOW: 'bg-yellow-300',
  RED: 'bg-red-600',
};

export function getStatusColor(status) {
  const statusCategory = status.toString()[0];
  switch (statusCategory) {
    case '4':
      return STATUS_COLOR.YELLOW;
    case '5':
      return STATUS_COLOR.RED;
    default:
      return STATUS_COLOR.GRAY;
  }
}

export function getStatusCodeName(status) {
  if (STATUSES_CODE_NAMES[status]) {
    return STATUSES_CODE_NAMES[status];
  }
  return '';
}

export function isDigiSignApp() {
  return window?.navigator?.userAgent.includes('DigiSignApp');
}

export function pathIncludesElements(path, elements) {
  if (!Array.isArray(elements)) {
    elements = [elements];
  }
  elements = elements.map(element => element.toLowerCase());

  return !!path.some(pathElement => elements.includes(pathElement.tagName?.toLowerCase()));
}

export function isNilOrEmptyString(value) {
  return value === '' || value === undefined || value === null;
}

/**
 * This returns true if <br>
 * x is y<br>
 * or<br>
 * x is included in y
 * @function is
 * @param {any} x - element to check
 * @param {any | any[]} y - single element or array of elements to check against
 * @returns {boolean}
 */
export function is(x, y) {
  if (!Array.isArray(y)) {
    y = [y];
  }
  return y.includes(x);
}

export function isObject(object) {
  return object && typeof object === 'object' && !Array.isArray(object);
}

export function isObjectAndHasProperty(object, property) {
  return isObject(object) && Object.prototype.hasOwnProperty.call(object, property);
}

export function getValueOrSelf(ObjectOrValue) {
  return isObjectAndHasProperty(ObjectOrValue, 'value') ? ObjectOrValue.value : ObjectOrValue;
}
