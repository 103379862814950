import { loadLanguageAsync } from '@I18next/index';
import { createInertiaApp, router } from '@inertiajs/vue3';
import { createApp, h } from 'vue';
import { createPinia } from 'pinia';
import axios from 'axios';
import VueDefault from '@/vue';
import * as Sentry from '@sentry/vue';
import './utils/polyfills.js';
import '../theme/app.css';

/* some assets are not used in vue app but are used on symfony asset() calls. They need to be used to be included in manifest.json */

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import logoWhite from '@assets/images/logo_white.png';

/**
 * Imports the given page component from the page record.
 */
function resolvePageComponent(name, pages) {
  for (const path in pages) {
    if (path.endsWith(`${name.replace('.', '/')}.vue`)) {
      return typeof pages[path] === 'function' ? pages[path]() : pages[path];
    }
  }

  throw new Error(`Page not found: ${name}`);
}

createInertiaApp({
  progress: {
    color: '#29d',
  },
  resolve: async name => {
    return (await resolvePageComponent(name, import.meta.glob(`@/pages/**/*.vue`)))?.default;
  },
  setup({ App, el, props, plugin }) {
    const pageProps = JSON.parse(el.dataset.page).props;
    loadLanguageAsync(pageProps.locale).finally(() => {
      const pinia = createPinia();
      const instance = createApp({ render: () => h(App, props) }).use(plugin);

      VueDefault(instance);

      if (pageProps.sentryDsn) {
        Sentry.init({
          app: instance,
          environment: import.meta.env.MODE,
          dsn: pageProps.sentryDsn,
          integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
            Sentry.captureConsoleIntegration({ levels: ['error'] }),
            Sentry.httpClientIntegration({
              failedRequestStatusCodes: [[500, 599], 408, 413, 424, 429],
            }),
          ],

          // Set tracesSampleRate to 1.0 to capture 100%
          // of transactions for performance monitoring.
          // We recommend adjusting this value in production
          tracesSampleRate: 0.1,
          logErrors: true,
          ignoreErrors: [
            '[vite]',
            '[Vue warn]',
            '[DGS] No title translate key was given to title hook',
            'Warning: Cannot load system font',
            'HTTP Client Error with status code: 401',
            'Request failed with status code 403',
            'Request failed with status code 400',
            'Emit translator-detect',
            'TronLink initiated',
            'TRN: languageDetected',
            'TRN: Resolve',
            'TRN: Message',
            'TronWeb: ',
            'AxiosError: Network Error',
            'AxiosError: Request failed with status code 404',
            'Non-Error promise rejection captured with value: Timeout',
          ],

          // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
          //tracePropagationTargets: ['app.digisign.localhost', 'app.digisign.digital.cz', 'app.digisign.org'],

          // Capture Replay for 10% of all sessions,
          // plus for 100% of sessions with an error
          replaysSessionSampleRate: 0.1,
          replaysOnErrorSampleRate: 1.0,
        });

        let firstNavigation = true;
        const metaBaggage = document.querySelector('meta[name="baggage"]')?.content ?? null;
        const metaTraceId = document.querySelector('meta[name="sentry-trace"]')?.content ?? null;

        router.on('navigate', event => {
          const sentryData = event.detail.page.props || {};

          if (axios.defaults !== undefined) {
            axios.defaults.headers.common['baggage'] = firstNavigation ? metaBaggage : sentryData.baggage;
            axios.defaults.headers.common['sentry-trace'] = firstNavigation ? metaTraceId : sentryData.traceId;
          }

          firstNavigation = false;
        });
      }

      instance.use(pinia);
      instance.mount(el);
    });
    axios.interceptors.request.use(config => {
      config.headers['X-Requested-With'] = 'XMLHttpRequest';
      return config;
    });
  },
}).then();
